.Navbar {
  width: 100%;
  background-color: #cedbf3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8c9df;
}

.navButtonWrapper {
  display: flex;
  justify-content: center;
  gap: 8em;
  box-shadow: 0.5px 1px 1px #9b4741;
  @media (max-width: 1066px) {
    display: none;
  }
}

.navDropdownWrapper {
  display: none;
  @media (max-width: 1066px) {
    box-shadow: 0.5px 1px 1px #9b4741;
    padding: 5px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    min-height: 40px;

    .dropdownButton {
      position: absolute;
      top: 5px;
      right: 15px;
      cursor: pointer;
      border: 1px solid #adc4eb;
      background-color: #adc4eb;
      box-shadow: 0.5px 1px 1px #78586f;
      border-radius: 50%;
      background-size: cover !important;
      background-image: url("../../assets/dropdownarrow.png");
      height: 42px;
      width: 42px;
      &:active {
        background-color: #1c3c73;
        border: 1px solid #9b4741;
      }
    }

    .dropdownOptionsWrapper {
      display: flex;
      flex-direction: column;
      max-height: 42px;
      transition: 1s;
      overflow: hidden;

      &.open {
        max-height: 1000px;
      }
    }
  }
}
