.heading {
  font-size: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.masthead {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 350px;
  overflow: hidden;
  background-size: cover !important;
  background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 49%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    url("../../assets/jason-hero.jpeg") no-repeat center center scroll;

  @media (max-width: 768px) {
    height: 160px;
  }

  box-shadow: 1px 2px 2px #686cb5;
}

.headingButton {
  width: 100%;
  margin: 0 auto;
  border: 0;
  box-shadow: 0.5px 1px 1px #9b4741;
  padding: 5px 30px;
  text-align: center;
  background-color: #f8c9df;
  cursor: pointer;
}
