.socialButtonWrapper {
  position: relative;
  margin: 10px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
    padding: 10px 0;
    margin: 10px 0;
  }

  .socialButton {
    height: 42px;
    width: 42px;
    cursor: pointer;
    border: none;
    background-size: cover !important;
    border-radius: 1em;
    &:hover {
      background-color: lighten(#efefef, 25%);
    }
    &:active {
      border-radius: 1em;
    }

    &.ig {
      background-image: url('../../assets/social/instagram.png');
    }
    &.tw {
      background-image: url('../../assets/social/twitter.png');
    }
    &.yt {
      background-image: url('../../assets/social/youtube.png');
    }
    &.tt {
      background-image: url('../../assets/social/tiktok.png');
    }
    &.fb {
      background-image: url('../../assets/social/facebook.png');
    }
  }
}
