.Bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contentText {
    &.bioText {
      display: inline;

      margin-bottom: 15px;
      position: relative;
      line-height: 2em;
      @media (max-width: 768px) {
        padding: 0 25px;
      }
    }
  }

  .imgWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    @media (max-width: 930px) {
      flex-direction: column;
    }

    .jasonImg {
      margin: 60px 0 0;
      border-radius: 6px;
      width: 90%;
      &.tcb {
        height: 400px;
        width: auto;
        margin: 0;
        @media (max-width: 930px) {
          height: auto;
          width: 90%;
          margin-bottom: 30px;
          width: 90%;
        }
      }
    }
  }

  .bioText2Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
      margin: 0;
    }
  }
  .bioEmailButton {
    margin-top: 1em;
    width: 100%;

    .contentText {
      font-size: 2em;
      @media (max-width: 1066px) {
        font-size: 1.5em;
      }
    }
  }

  .bioCopyMessageClassName {
    top: 16px;
    left: 40%;
    @media (max-width: 768px) {
      left: 40%;
    }
  }

  .videoWrapper {
    background: #efefef;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 48px;
    @media (max-width: 768px) {
      margin-bottom: 0;
      padding-top: 10px;
      width: 97%;
    }
    .videoClip {
      border-radius: 3px;
      width: 640px;
      height: 360px;
      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }

  .videoWrapper:last-of-type {
    padding-bottom: 48px;
    @media (max-width: 768px) {
      padding-bottom: 0;
    }
  }
}
