.videoCard{
  background-color: #F5F5F5;
  padding: 20px;
  margin: 10px 0;
  border-radius: 1em;
  border: 1px solid #f2f1df;
  border-radius: 3px;
  box-shadow: 1px 2px 2px #f2f1df;
  @media (max-width: 768px) {
    padding: 0;
  }
  .videoTitle{
    margin-left: 4px;
  }
}

