.Video {
  .videoSectionSelectorWrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 3px;
    box-shadow: 1px 2px 2px #f2f1df;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .videoSelectorButton {
      border: 3px solid #fff;
      cursor: pointer;
      padding: 1em;
      background-color: #cedbf3;
      white-space: nowrap;

      &.selected {
        background-color: #f8cadf;
      }
      @media (min-width: 768px) {
        flex-direction: column;
        &:first-of-type {
          border-left: 6px solid #fff;
        }
        &:last-of-type {
          border-right: 6px solid #fff;
        }
      }
    }
  }

  .vidWrapper {
    background-color: 'black';
  }

  // .selectedVideo {
  //   width: 100%;
  //   height: 400px;
  // }

  .embeddedVideo {
    display: block;
    width: 100%;
    height: 400px;
  }

  .loadingVideo {
    display: none;
  }

  .videoSectionHeading {
    background-color: #f7c9df;
  }

  // .videoSectionWrapper{
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
}
