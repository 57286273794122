.App {
  background-color: #cedbf3;
  padding-bottom: 100px;
  min-height: 100vh;

  .contentWrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .contentHeading {
      display: flex;
      justify-content: center;
      &h2 {
        font-size: 32px;
      }
      &h3 {
        font-size: 28px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .contentSubheading {
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .contentText {
      padding: 0;
      margin: 0;
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .content {
      padding: 10px 80px 40px;
      width: 50%;
      border: 1px solid #f2f1df;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 1px 2px 2px #f2f1df;
      @media (max-width: 768px) {
        width: 100%;
        padding: 10px 0px 80px 0px;
      }
    }

    .wideContent {
      padding: 10px 80px 40px;
      width: 75%;
      border: 1px solid #f2f1df;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 1px 2px 2px #f2f1df;
      @media (max-width: 768px) {
        width: 100%;
        padding: 10px 0 80px 0px;
      }
    }
  }
}
