.emailButtonWrapper {
  position: relative;

  .emailButton {
    border-radius: 1em;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#efefef, 25%);
    }
    &:active {
      border-radius: 1em;
    }
  }

  .emailText {
    background-color: transparent;
    border-radius: 1em;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#efefef, 25%);
    }
    &:active {
      border-radius: 1em;
    }
  }

  .copyMessage {
    height: 1em;
    background-color: 'white';
    position: absolute;
    top: 24px;
    left: 100px;
    @media (max-width: 768px) {
      left: 80px;
    }
  }
}
