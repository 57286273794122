.NavButton {
  font-size: 20px;
  background-color: transparent;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 1em;
  margin: 3px;

  &:hover {
    background-color: lighten(#9b4741, 15%);
  }

  &:active {
    background-color: #9b4741;
  }

  &.selected {
    text-decoration: underline;
    text-decoration-color: #9b4741;
  }
}
